// ** Reducers Imports
import auth from "./slices/auth"
import layout from "./slices/layout"

const rootReducer = {
  auth,
  layout
}

export default rootReducer
